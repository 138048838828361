import React, {Component} from 'react';
import '../css/components/SectionControl.css';

var PubSub = require("../pub_sub");

var sections = ["overview", "topics", "geography","characteristics", "time",  "commerce"]


var scrollPosition = 0;
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: ()=> {
      supportsPassive = true;
    }
  });
  window.addEventListener("testPassive", null, opts);
  window.removeEventListener("testPassive", null, opts);
} catch (e) {}


class SectionControl extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageControlIndex: 0,
    }
  }

  render (){
    return (
      <div className = "section-control">
        <div className = "section-name mobile">
          {((this.state.pageControlIndex === 0)? this.props.label : sections[this.state.pageControlIndex])}
        </div>
      {
        sections.map((section, index)=>{
          var isSelected = (index === this.state.pageControlIndex)? "selected" : "";
          return(
            <button id = {"section_control_button_" + index} key = {"section_control_button_" + index} className = {"section-control-button " + isSelected} value = {index} onClick = {()=>{
              var tempTop = document.getElementById(sections[index] + "_section").offsetTop;
              var scrollPosition = document.getElementById("segment_view").scrollTop + document.getElementById("top_bar").offsetHeight;
                // now account for fixed header
                if (scrollPosition > tempTop) {
                  scrollPosition += 70;
                }

              document.getElementById("segment_view").scrollBy({top: tempTop - scrollPosition, left: 0, behavior: "smooth"});
            }}>
              <div className = "section-name sp-c-2">
                {((index === 0)? this.props.label:section)}
              </div>
              <div className = "section-progress">
                <div id = {"progress_bar_" + index} className = "bar"/>
              </div>
            </button>
          )
        })
      }
      </div>
    )
  }

  componentDidMount(){
    PubSub.subscribe.update_section_control((event, index)=>{
      this.updatePageIndicators(this.state.pageControlIndex + index, true)
    });

    document.getElementById("segment_view").addEventListener("scroll", this.detectScroll.bind(this),  (supportsPassive)? { passive: true } : false);
  }

  componentWillUnmount(){
    document.getElementById("segment_view").removeEventListener("scroll", this.detectScroll.bind(this));
  }

  detectScroll(e){
    var currentSegment = document.getElementById(sections[this.state.pageControlIndex] + "_section").getBoundingClientRect();
    var pageProgress = (1 - ((currentSegment.bottom - (window.innerHeight/2))  / Math.abs(currentSegment.top - currentSegment.bottom))) * 100;
    document.getElementById("progress_bar_" + this.state.pageControlIndex).style.width = pageProgress + "%";


    if (e.target.scrollTop === 0) {
      document.getElementById("segment_background").style.filter = "blur(0px)";
      for (var i = this.state.pageControlIndex; i >= 0; i--) {
        document.getElementById("progress_bar_" + i).style.width = 0;
      }
      this.updatePageIndicators(0, false);
    }
    else if (scrollPosition < e.target.scrollTop ){
      if (this.state.pageControlIndex < 1) {
        document.getElementById("segment_background").style.filter = "blur(" + (((pageProgress - 50)/ 50) * 20) + "px)";
      }
      if (this.state.pageControlIndex < sections.length - 1 ) {
        if (pageProgress >= 100) {
          document.getElementById("progress_bar_" + this.state.pageControlIndex).style.width = "100%";
          this.updatePageIndicators(this.state.pageControlIndex + 1, false);
        }
      }
    }
    else {
      if (this.state.pageControlIndex < 1) {
        document.getElementById("segment_background").style.filter = "blur(" + (((pageProgress - 50)/ 50) * 20) + "px)";
      }
      if ((this.state.pageControlIndex > 0 && pageProgress <= 0) || (this.state.pageControlIndex === 0 && pageProgress === 50)) {
        document.getElementById("progress_bar_" + this.state.pageControlIndex).style.width = 0;
        this.updatePageIndicators(this.state.pageControlIndex - 1, false);
      }
    }
    scrollPosition = e.target.scrollTop;
  }

  updatePageIndicators(index, animateMove){
    if (index !== this.state.pageControlIndex && index < sections.length && index >= 0) {
      //update pageControl
      document.getElementById("section_control_button_" + this.state.pageControlIndex).className = "section-control-button" ;
      document.getElementById("section_control_button_" + index).className += " selected";
      this.setState({
        pageControlIndex: index,
      });
    }
  }
}
export default SectionControl;
