import React, {Component} from 'react';
import '../css/components/ContactUs.css';
var https = require('https');


var PubSub = require("../pub_sub");

class ContactUs extends Component {

  render (){
    return (
      <div className = "contact-us">
        <div className = "background-cover" onClick = {()=>{
          PubSub.publish.contact_displayed();
        }}>
        </div>
        <div id = "contact_form" className = "contact-form">
          <button style = {{backgroundImage: "url('/img/misc/x_dark.svg')"}} className = "exit-button" onClick = {()=>{
            PubSub.publish.contact_displayed();
          }}>
          </button>
          <div className = "contact-header sp-h-3">
          contact us
          </div>
          <div className = "contact-message sp-p-2">
          We are happy to answer any questions or help you get started with our data.
          </div>
          <div className = "forms-container">
            <div className = "contact-column">
              <div className = "input-container">
                <div className = "input-header sp-c-2">
                  name
                </div>
                <input id = "contact_form_name" className = "contact-input sp-p-2" type="text"/>
              </div>
              <div className = "input-container">
                <div className = "input-header sp-c-2">
                  company name *
                </div>
                <input id = "contact_form_company"className = "contact-input sp-p-2" type="text"/>
                <div className= "error-message contact sp-p-3" id="contact_company_error">
                  <img className = "icon alert" src ="./img/icons/alert.svg"/>
                  Please provide your company name.
                </div>
              </div>
              <div className = "input-container" style = {{marginBottom: 0}}>
                <div className = "input-header sp-c-2">
                  work email address *
                </div>
                <input id = "contact_form_email" className = "contact-input sp-p-2" type="email" />
                <div className= "error-message contact sp-p-3" id="contact_email_error">
                  <img className = "icon alert" src ="./img/icons/alert.svg"/>
                  Please provide a valid email address.
                </div>
              </div>
            </div>
            <div className = "contact-column message">
              <div className = "input-header sp-c-2">
                your message
              </div>
              <textarea id = "contact_form_message" className = "input-message sp-p-2" type="text" placeholder = "Hi, I'd like to setup a time to chat."/>
            </div>
          </div>
          <button id = "contact_form_button" className = "contact-button sp-b-2" onClick = {()=>{
            this.submitForm();
          }}>
            submit
            <div className= "error-message form sp-p-3" id="contact_submission_error">
              <img className = "icon alert" src ="./img/icons/alert.svg"/>
              An error occured when submitting the form. Please try again.
            </div>
          </button>
        </div>
        <div id = "success_message" className = "success-message">
          <img className = "" src ="./img/icons/success.svg"/>
          <div className = "contact-header sp-h-3" style = {{margin: "16px 0 0"}}>
            thank you
          </div>
          <div className = "contact-message sp-p-2" style = {{margin: "8px 0 0"}}>
            We have received your message and will be in touch soon.
          </div>
        </div>
      </div>
    );
  }

  submitForm(){
    var button = document.getElementById("contact_form_button");
    button.disabled = true;

    var validForm = true;

    if (!this.validateEmail(document.getElementById("contact_form_email").value)){
      document.getElementById("contact_email_error").style.display = "flex";
      validForm = false;
    }

    if (document.getElementById("contact_form_company").value === "") {
      document.getElementById("contact_company_error").style.display = "flex";
      validForm = false;
    }

    if (validForm) {
      var payload = JSON.stringify({
        "fields": [
          {
            "name": "email",
            "value": document.getElementById("contact_form_email").value
          },
          {
            "name": "firstname",
            "value": document.getElementById("contact_form_name").value
          },
          {
            "name": "comany",
            "value": document.getElementById("contact_form_company").value
          },
          {
            "name": "message",
            "value": document.getElementById("contact_form_message").value
          }
        ],
        "context": {
          "pageUri": window.location.href,
          "pageName": "Taxonomy Contact"
        },
        "skipValidation": true
      });

      this.sendContact(payload);
    }
      button.disabled = false
  }

  //send contact information to hubspot
  sendContact(payload){

    var options = {
     hostname: 'api.hsforms.com',
     path: '/submissions/v3/integration/submit/2575722/c753f874-cf2c-4e92-9bc1-9b38162f7191',
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'Content-Length': Buffer.byteLength(payload),
     }
   }

   var request = https.request(options, function(response){

     response.setEncoding('utf8');
     response.on('data', function(chunk){
       //google tag manager
       window.dataLayer.push({
         event:'taxonomy_contact_form',
         email: document.getElementById("email_input").value
       });

       window.fbq('trackCustom', 'taxonomy_contact_form');

       document.getElementById("contact_form").remove();
       document.getElementById("success_message").style.display = "flex";

       //add linkedin conversion code to success message
       var linkedinConversion = document.createElement('img');
       linkedinConversion.id = "linkedin_conversion";
       linkedinConversion.style = "height: 1; width; 1; display:none;";
       linkedinConversion.alt = "";
       linkedinConversion.src = "https://dc.ads.linkedin.com/collect/?pid=274474&conversionId=754402&fmt=gif";
       document.getElementById("success_message").appendChild(linkedinConversion);

      setTimeout(()=>{
        PubSub.publish.contact_displayed();
      }, 3000)
     });
   });

   request.on('error', function(e){
     document.getElementById("contact_submission_error").style.display = "flex";
   });

   request.write(payload);
   request.end();
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


}
export default ContactUs;
