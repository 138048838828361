import React , {Component} from 'react';
import '../css/GridView.css';
import GridItem from './GridItem';

var PubSub = require('../pub_sub');

class GridView extends Component{
  constructor(props){
    super(props);
    //setup
    if (window.location.pathname !== "/") {
      var tempValue = new URL(window.location).searchParams.get("segment");
      PubSub.publish.segment_selected(tempValue)
    }

    var categories = [];
    for (var i = 0; i < props.segments.length; i++) {
      if (categories.indexOf(props.segments[i].category) === -1) {
        categories.push(props.segments[i].category);
      }
    }
    this.state = {
      top:  ((window.location.pathname === "/")? "0" : "100%"),
      gridSegments: [],
      lockedState: "",
      categories: categories,
      expandCategories: false,
      selectedCategory: -1
    }
  }

  componentDidMount(){
    PubSub.subscribe.show_grid_view(()=>{
      this.changeTopPosition();
    })

    if (window.location.pathname === "/") {
      this.setState({
        lockedState: "locked"
      });
    }
  }

  render(){
    return(
      <div id = "grid_view" className = {"grid-view " + ((this.props.home)? "home" : "" )} style = {{top: this.state.top}}>
        <div id = "grid_toolbar"className = "grid-toolbar">
          <button id = "grid_view_exit_button" style = {{backgroundImage: "url('/img/misc/x.svg')", display:  ((window.location.pathname === "/")? "none" : "inherit")}} className = "toolbar-button" onClick = {()=>{
            this.changeTopPosition()
          }}/>
          <div className = "dropdown-container">
            <button className="dropdown-button sp-i-1" type="button" onClick = {()=>{
              this.setState({
                expandCategories: !this.state.expandCategories
              });
            }}>
              {((this.state.selectedCategory >= 0)? this.state.categories[this.state.selectedCategory] : "Select Section")}
              <img className="caret" src = "./img/icons/arrow.svg"/>
            </button>
            <ul className = {"dropdown-menu sp-i-1 " + ((this.state.expandCategories)? "expanded" : "")}>
              <div className = "background-cover grid" onClick = {()=>{
                this.setState({
                  expandCategories: false
                })
              }}/>
            {
              this.state.categories.map((category, index)=>{
                return(
                  <li key = {"category_" + index}onClick = {()=>{
                    document.getElementById("grid_row_category_" + index).scrollIntoView({
                      behavior: "smooth",
                      block: "start"
                    });

                    this.setState({
                      expandCategories: false,
                      selectedCategory: index
                    })
                  }}>
                    {category}
                  </li>
                )
              })
            }
            </ul>
          </div>

        </div>
        <div id = "grid_row" className = {"grid-row " + this.state.lockedState}>
        {
          this.state.categories.map((category, index)=>{
            return(
              <div key = {"grid_row_category_" + index} id = {"grid_row_category_" + index} className = "grid-row-category">
                <div className = "section">
                  <div className = "label sp-h-4">
                    {category}
                  </div>
                </div>
                <div className = "grid-items-container">
                  {this.generateSegmentsGrid(category)}
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }

  closeCategoriesMenu(){

  }

  generateSegmentsGrid(category){
    var categorySegments = this.props.segments.filter(segment => segment.category === category).map((segment, index)=>{
       return(
         <GridItem key = {category + "_" + index} segment = {segment}/>
       )
    });
    return (categorySegments);
  }

  changeTopPosition(){
    this.setState({
      top: (this.state.top === 0)? "100%" : 0
    })
  }
}
export default GridView;
