import React, { Component } from "react";
import {Redirect} from 'react-router-dom'
import GridView from '../components/GridView';

import '../css/App.css';
import '../css/HomeView.css';

var segmentsList;
var metadataURL = "https://s3.amazonaws.com/spatial-cdn/taxonomy-api/segments_metadata.json.min.gz";
var PubSub = require('../pub_sub');
var request = require('request');
var zlib = require('zlib');

var ua = window.navigator.userAgent;
/* MSIE used to detect old browsers and Trident used to newer ones*/
var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;


class HomeView extends Component {
  constructor(props){
    super(props);

    this.state = {
      nextSegmentIndex: 0,
      currentSegmentIndex: 10,
      videoLoaded: false
    }
  }

  componentDidMount() {
    if (!is_ie) {
      this.getSegmentData();
    }
  }

  componentWillUnmount(){
    if (!is_ie) {
      PubSub.clear_all();
    }
  }

  componentDidUpdate(prevProps, prevState){
    if (!is_ie) {
      if (prevState.selectedSegment === undefined) {

        PubSub.subscribe.to_segment_view((event, data)=>{
          this.setState({
            selectedSegment: data
          });
        });
      }
    }
  }
  render() {
    if (is_ie) {
      var destination = {
        pathname: 'unsupported',
      }
      return (
        <Redirect to={destination} />
      )
    }

    if (this.state.selectedSegment !== undefined) {
      if (this.state.selectedSegment !== "") {
        var destination = {
          pathname: 'segments',
          search: "?segment=" + this.state.selectedSegment
        }
        return (
          <Redirect to={destination} />
        )
      }

      return (
        <div id="home_view" className="home-view">
          <div id="loading_container" className = "loading-conatiner" style = {{display: ((this.state.selectedSegment !== undefined && this.state.videoLoaded)? "none" : "inherit")}}>
            <img alt = "Loading some good stuff!" className = "loading-icon" src="/img/misc/loader.svg"/>
          </div>
          <div className = "title-navigation" style = {{border: "none"}}>
            <a href= "https://spatial.ai">
              <img alt = "Spatial.ai" style={{height: "23px"}} src="/img/logotype_light.png"/>
            </a>
          </div>
          <div id = "hero_container" className="hero-container" >
            <div id = "segment_image_container" className = "segment-image-container" style = {{zIndex: -1}}>
              <div className = "image-overlay"/>
              <video id = "hero_video" className = "video-view" autoPlay loop muted onLoadedData= {()=>{
                this.showView();
              }}>
                <source src="img/hero_video.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>

            <div className = "hero-content">
              <div className = "header sp-h-0">
                Data Taxonomy
              </div>
              <div className = "body sp-p-1">
                Explore over 70 Geosocial segments of real human behavior. Measured using social data over any geographic location.
              </div>
              <div className = "cta-container">
                <button id = "explore_the_segments_cta" className = "cta main sp-b-1" onClick ={()=>{
                  document.getElementById("grid_view").scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
                }}>
                  explore segments
                </button>
                <button id = "read_whitepaper_cta" className = "cta secondary sp-b-1" onClick ={()=>{
                    window.open('https://www.spatial.ai/post/geosocial-segmentation-whitepaper','_blank')
                  }}>
                  read whitepaper
                </button>
              </div>
            </div>
            <div className = "overview-guide">
              <div id = "guide_text" className = "guide-text sp-c-2">
                explore segments
              </div>
              <div id = "guide_arrow" className = "guide-arrow">
              </div>
            </div>
          </div>
          <GridView segments = {segmentsList} home = {true}/>
        </div>
      )
    }
    else {
      return(
        <div id="loading_container" className = "loading-conatiner">
          <img alt = "Loading some good stuff!" className = "loading-icon" src="/img/misc/loader.svg"/>
        </div>
      )
    }
  }

  showView(){
    this.setState({
      videoLoaded: true
    });
  }

  getSegmentData(){
    request.get({
      url : metadataURL,
      headers: {
        'Accept-Encoding' : 'gzip',
      },
      encoding : null  // it is very import!!
    }, (err, res, body)=> {
      //check res header it is gzip
      //now body it is gzip stream buffer
      zlib.unzip(body, (err, buffer)=> {
        var tempJSON = JSON.parse(buffer.toString());
        segmentsList = Object.keys(tempJSON).map((segment)=>{
          return(tempJSON[segment])
        });

        /*
        var exportList = Object.keys(tempJSON).map((segment)=>{
          var tempObj = tempJSON[segment];
          return({
            id: tempObj.id,
            label: tempObj.label,
            description: tempObj.sections.overview.description,
            category: tempObj.category,
            topics: tempObj.sections.topics,
            characteristics: tempObj.sections.characteristics,
            time: tempObj.sections.time.copy,
            commerce: tempObj.sections.commerce
          })
        })

        console.log(JSON.stringify(exportList));
        */

         this.setState({
           selectedSegment: "",
         });
      });
    });
  }
}

export default HomeView;
