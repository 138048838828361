import React, {Component} from 'react';
import '../css/components/SegmentControl.css';
var PubSub = require('../pub_sub');


class SegmentControl extends Component {
  constructor(props){
    super(props);
    this.state = {
      swipeDirection: ""
    }
  }

  render (){
    return (
      <div id = "segment_control" className = "segment-control">
        <div id = "swipe" className = {"swipe " + this.state.swipeDirection }>
        </div>
        <button className = "navigation" onClick = {()=>{
          this.setState({
            swipeDirection: "left-right"
          }, ()=>{
            setTimeout(()=> {
              PubSub.publish.segment_selected(this.props.info.prevSegment.id)
            }, 375)
          });
        }}>
          <img alt = "Previous Segment Thumbnail" key = {"navigation_image_" + this.props.info.prevSegment.id} id = {"navigation_image_" + this.props.info.prevSegment.id} className ="navigation-image" src = {"/img/segment_images/" + this.props.info.prevSegment.id + ".jpg"}/>
          <div className = "image-overlay" />
          <div className = "content">
            <div className = "sp-c-2" style = {{pointerEvents: "none"}}>
              {this.props.info.prevSegment.id}
            </div>
            <div className = "sp-b-1" style = {{textTransform: "capitalize", pointerEvents: "none"}}>
              {this.props.info.prevSegment.label}
            </div>
            <div id = "test-des" className = "sp-p-1 thumbnail-description" style = {{pointerEvents: "none"}}>
              {this.props.info.prevSegment.description}
            </div>
          </div>
        </button>

        <button id = "segment_navigation_next" className = "navigation" onClick = {()=>{
          this.setState({
            swipeDirection: "right-left"
          }, ()=>{
              setTimeout(()=> {
                PubSub.publish.segment_selected(this.props.info.nextSegment.id)
            }, 375)
          });
        }}>
          <img alt = "Next Segment Thumbnail" key = {"navigation_image_" + this.props.info.nextSegment.id} id = {"navigation_image_" + this.props.info.nextSegment.id} className ="navigation-image" src = {"/img/segment_images/" + this.props.info.nextSegment.id + ".jpg"}/>
          <div className = "image-overlay" />
          <div className = "content">
            <div className = "sp-c-2" style = {{pointerEvents: "none"}}>
              {this.props.info.nextSegment.id}
            </div>
            <div className = "sp-b-1" style = {{textTransform: "capitalize", pointerEvents: "none"}}>
              {this.props.info.nextSegment.label}
            </div>
            <div className = "sp-p-1 thumbnail-description" style = {{pointerEvents: "none"}}>
              {this.props.info.nextSegment.description}
            </div>
          </div>
        </button>
      </div>
    );
  }

  componentDidMount(){
    document.getElementById("swipe").addEventListener("animationend", ()=>{
      this.setState({
        swipeDirection: ""
      });
    });
  }
}
export default SegmentControl;
