import React, {Component} from 'react';
import '../css/segment_sections/OverviewSection.css';

class OverviewSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobileSize: false
    }
  }
  render (){
    return (
      <div id = "overview_section" className = "section-view overview-section">
        <div className = "overview-content section-content">
          <div className = "sp-c-1 ani-1" >
            {this.props.segment.id}
          </div>
          <div className = "sp-h-1 ani-1" style = {{textTransform: "capitalize", animationDelay: "375ms", marginBottom: "16px"}}>
            {this.props.segment.label}
          </div>
          <div className = "sp-p-1 ani-1" style = {{animationDelay: "750ms", fontWeight: "500"}}>
            {this.props.segment.sections.overview.description}
          </div>
        </div>
        <div className = "overview-guide">
          <div id = "guide_text" className = "guide-text sp-c-2">
            explore segment
          </div>
          <div id = "guide_arrow" className = "guide-arrow">
          </div>
        </div>
      </div>
    );
  }
}
export default OverviewSection;
