import React, {Component} from 'react';
import '../css/segment_sections/FooterSection.css';

var PubSub = require('../pub_sub');
var https = require('https');

class FooterSection extends Component {
  render (){
    return (
      <div id = "footer_section" className = "section-view footer-section">
        <div className = "section-content footer-content wide">
          <div id = "sample_data_section" className = "footer-container sample ani-1">
            <img src = "./img/misc/bg.svg" className = "sample-background"/>
            <img src = "./img/misc/color_waves.svg" className = "waves" />
            <div className = "footer-title sp-h-2" style = {{marginBottom: "32px", justifyContent: "center"}}>
              access sample data
            </div>
            <div id  = "form_location">
              <div className = "sp-p-1 ani-1" style = {{marginBottom: "40px"}}>
                Download our sample package to receive data, guides, and heatmaps of our geosocial segments.
              </div>
              <div className = "form-container" style = {{ justifyContent: "center"}}>
                <button id = "sample_data_cta" className = "sample-data-button sp-b-2" onClick = {()=>{
                  window.location.href="https://www.spatial.ai/sample-data-download";
                }}>
                  download sample data
                </button>
              </div>
            </div>
            <div className="success-container sp-b-1" id="success_container">
              <span>
                Thank you!
                <br/>You will receive an email containing our sample data package shortly.
                <br/>Read our <a className = "link" href="https://www.spatial.ai/post/geosocial-segmentation-whitepaper">Whitepaper</a> to learn more about the data.
              </span>
            </div>
          </div>
          <div id = "share_section" className = "footer-container">
            <div className = "footer-title sp-h-2 ani-1">
              <img src = "./img/icons/download.svg" className = "footer-icon"/>
              Download PDF
            </div>
              <div className = "share-container">
              <div className = "share-content">
                <div className = "sp-p-1 ani-1"  style = {{marginBottom: "40px"}}>
                  Looking for a print-friendly overview of all our segments? Download the PDF version of our taxonomy.
                </div>
              </div>
            </div>
            <button id = "_cta" className = "sample-data-button sp-b-2" onClick = {()=>{
              window.open("https://cloud.spatial.ai/index.php/s/fTEmxDo5omtfXYt", "_blank");
            }}>
              download taxonomy PDF
            </button>
          </div>
          <div id = "share_section" className = "footer-container">
            <div className = "footer-title sp-h-2 ani-1">
              <img src = "./img/icons/share.svg" className = "footer-icon"/>
              share this segment
            </div>
              <div className = "share-container">
              <div className = "share-content">
                <div className = "sp-p-1 ani-1"  style = {{marginBottom: "40px"}}>
                  Copy a link to this segment page to share with you team, or share this segment with your network on social media.
                </div>
                <div className = "sharing-container ani-1">
                  <button style = {{backgroundImage: "url(/img/sharing/link.svg)"}} id = "link_share_button" className = "share-button" onClick ={(e)=>{PubSub.publish.share_selected(e.target.id)}}/>
                  <button style = {{backgroundImage: "url(/img/sharing/email.svg)"}} id = "email_share_button" className = "share-button" onClick ={(e)=>{PubSub.publish.share_selected(e.target.id)}}/>
                  <button style = {{backgroundImage: "url(/img/sharing/facebook.svg)"}} id = "fb_share_button" className = "share-button" onClick = {(e)=>{PubSub.publish.share_selected(e.target.id)}}/>
                  <button style = {{backgroundImage: "url(/img/sharing/twitter.svg)"}} id = "tw_share_button" className = "share-button" onClick ={(e)=>{PubSub.publish.share_selected(e.target.id)}}/>
                  <button style = {{backgroundImage: "url(/img/sharing/linkedin.svg)"}} id = "li_share_button" className = "share-button" onClick ={(e)=>{PubSub.publish.share_selected(e.target.id)}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  submitForm(){
    var button = document.getElementById("sample_data_cta");
    button.disabled = true;

    if (!this.validateEmail(document.getElementById("email_input").value)){
      button.disabled = false;
      document.getElementById("error_message").style.display = "flex";
    }
    else {
      var payload = JSON.stringify({
        "fields": [
          {
            "name": "email",
            "value": document.getElementById("email_input").value
          }
        ],
        "context": {
          "pageUri": window.location.href,
          "pageName": "Taxonomy"
        },
        "skipValidation": true
      });
      this.sendContact(payload);

    }
  }

  //send contact information to hubspot
  sendContact(payload){
    var button = document.getElementById("sample_data_cta");
    button.disabled = false;

    var options = {
     hostname: 'api.hsforms.com',
     path: '/submissions/v3/integration/submit/2575722/930f266e-7354-4854-a8d3-c3ca8f7c3341',
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'Content-Length': Buffer.byteLength(payload),
     }
   }

   var request = https.request(options, function(response){
     response.on('data', function(chunk){
       console.log(response.statusCode);
       if (response.statusCode === 200) {
         //google tag manager
         window.dataLayer.push({
           event:'sample_data_form_submitted',
           email: document.getElementById("email_input").value
         });

         window.fbq('trackCustom', 'sample_data_form_submitted');


         document.getElementById("form_location").remove();
         document.getElementById("success_container").style.display = "flex";
         document.getElementById("success_container").style.opacity = 1;

         //add linkedin conversion code to success message
         var linkedinConversion = document.createElement('img');
         linkedinConversion.id = "linkedin_conversion";
         linkedinConversion.style = "height: 1; width; 1; display:none;";
         linkedinConversion.alt = "";
         linkedinConversion.src = "https://dc.ads.linkedin.com/collect/?pid=274474&conversionId=754402&fmt=gif";
         document.getElementById("success_container").appendChild(linkedinConversion);
       }
       else if (response.statusCode === 400) {
         document.getElementById("sample_data_cta").disabled = false;
         document.getElementById("error_message").style.display = "flex";
         document.getElementById("error_message").lastChild.data = "The email that you entered is invalid. Please try again with your business email.";
       }
       else {
         document.getElementById("sample_data_cta").disabled = false;
         document.getElementById("error_message").style.display = "flex";
         document.getElementById("error_message").lastChild.data = "An error occured when submitting the form. Please try again.";
       }
     });
   });

   request.on('error', function(e){
     document.getElementById("sample_data_cta").disabled = false;
     document.getElementById("error_message").style.display = "flex";
     document.getElementById("error_message").lastChild.data = "An error occured when submitting the form. Please try again.";
   });

   request.write(payload);
   request.end();
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
export default FooterSection;
