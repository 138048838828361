import React, {Component} from 'react';
import '../css/App.css';
import '../css/UnsupportedView.css';

class UnsupportedView extends Component {
  render (){
    return (
      <div className = "unsupported-view">
        <div className = "unsupported-navigation" >
          <a href= "https://spatial.ai">
            <img alt = "Spatial.ai" style={{height: "23px"}} src="/img/logotype_light.png"/>
          </a>
        </div>
        <div className = "sp-h-2" style = {{marginBottom: "32px"}}>
          internet explorer is not supported
        </div>
        <div className = "supported-container" style = {{marginBottom: "32px"}}>
          <div className = "sp-h-3" style = {{marginBottom: "16px"}}>
            supported browsers:
          </div>
          <div className = "sp-b-1" style = {{textTransform: "capitalize"}}>
            chrome
          </div>
          <div className = "sp-b-1" style = {{textTransform: "capitalize"}}>
            safari
          </div>
          <div className = "sp-b-1" style = {{textTransform: "capitalize"}}>
            firefox
          </div>
        </div>
        <div className = "sp-b-1" style = {{marginBottom: "32px"}}>
          <a className = "link" href="https://cloud.spatial.ai/index.php/s/QixSAL5Q7AAHd8T" target="_blank">Click here</a> to download our complete taxonomy in PDF format.
        </div>
        <div className = "sp-b-1">
          You can also read our <a className = "link" href="https://www.spatial.ai/post/geosocial-segmentation-whitepaper" target="_blank">Whitepaper</a> for a detailed overview of the Spatial Data model.
        </div>
      </div>
    );
  }
}
export default UnsupportedView;
