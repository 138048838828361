import React, {Component} from 'react';
import '../css/GridView.css';
import history from '../history';

var PubSub = require("../pub_sub");

class GridItem extends Component {
  constructor(props){
    super(props);
    this.state = {
      itemHover: false
    }
  }

  render (){
    return (
      <button id = {"grid_item: " + this.props.segment.id} key = {"grid_item_" + this.state.segmentid} className = "grid-item"  onClick = {()=>{this.gridItemSelected(this.props.segment.id)}}>
        <div className = "grid-content">
          <img alt = {this.props.segment.label + " Segment Thumbnail"} className = "grid-image" src={"/img/segment_images_grid/" + this.props.segment.id + ".jpg"}/>
          <div className = "grid-body">
            <span className = "grid-description sp-p-3">
              {this.props.segment.sections.overview.description}
            </span>
          </div>
        </div>
        <div className = "grid-label sp-p-2">
          {this.props.segment.label}
        </div>
      </button>
    );
  }

  gridItemSelected(value){
    if (window.location.pathname === "/") {
      PubSub.publish.to_segment_view(value);
      history.push({
        pathname: '/segments',
        search: '?segment=' + value
      })
    }
    else {
      document.getElementById("segment_view").scrollTo({top: 0, left: 0,});
      PubSub.publish.segment_selected(value);
      PubSub.publish.show_grid_view();
    }
  }
}
export default GridItem;
