import React, {Component} from 'react';
import '../css/segment_sections/TopicsSection.css';

class TopicsSection extends Component {

  render (){
    return (
      <div id = "topics_section" className = "section-view topics-section">
        <div className = "section-content">

          <div className = "header sp-h-2 ani-1">
            example topics
          </div>
          <div className = "description sp-p-1 ani-1">
            Segments capture real human behaviors and personalities as expressed through social media data. These example topics provide a window into some of the most frequent types of phrases, terms, and activities that users discuss on social media that represent this segment. This list is nowhere near exhaustive, and should be used as a reference for the type of interactions that are occuring in a location.
          </div>
          <div className = "topics-container">
            {
              this.props.topics.list.map((topic, index)=>{
                return(
                  <div key = {"topic_box_" + index} className = "topic-box sp-b-2 ani-1" id = {"topic_box_" + index}>
                  {topic}
                  </div>
                )
              })
            }
          </div>
          <div className = "description sp-p-1 ani-1">
            {this.props.topics.copy}
          </div>
        </div>
      </div>
    );
  }
}
export default TopicsSection;
