import React, {Component} from 'react';
import '../css/segment_sections/TimeSection.css';
import paper from 'paper'

var canvas;
var path;
var pathGradient;

class TimeSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      sectionDividers: [],
      tabIndex: 0
    }

    this.drawGraph = this.drawGraph.bind(this);
  }

  render (){
    return (
      <div id = "time_section" className = "section-view time-section">
        <div className = "section-content">
          <div className = "header sp-h-2 ani-1">
            tendencies over time
          </div>
          <div className = "description sp-p-1 ani-1">
            Certain segments may tend to be higher at different times of day, days of the week, or months out of the year. Understanding the relationship a segment has with time can provide further insight into the behaviors and personalities being measured.
          </div>
          <div className = "tab-container">
            {
              Object.keys(this.props.time).map((time, index)=>{
                if (time !== "copy") {
                  var title;
                  if (time === "tod") {
                    title = "time of day";
                  }
                  else if (time === "dow") {
                    title = "day of week";
                  }
                  else {
                    title = "month of year";
                  }

                  var isSelected = (index === this.state.tabIndex)? "selected" : "";
                  return(
                    <button id = {"time_button_" + index} key = {"time_button_" + index} className = {"time-tab sp-b-2 " + isSelected} onClick = {()=>{
                      this.setState({
                        tabIndex: index
                      }, ()=>{
                        this.updateDividers(time);
                      });
                    }}>
                      {title}
                    </button>
                  )
                }
              })
            }

          </div>
          <div id = "time_table" className = "time-table">
            <div id = "time_cover" className = "time-cover"/>
            <canvas id = "time_canvas" className = "time-canvas">
            </canvas>
            {this.state.sectionDividers}
          </div>
          <div className = "description sp-p-1 ani-1">
            {this.props.time.copy}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount(){
    canvas = document.getElementById('time_canvas');
    paper.setup(canvas);
    this.updateDividers(Object.keys(this.props.time)[this.state.tabIndex]);
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps){
    if (this.props.time.copy !== prevProps.time.copy) {
      this.updateDividers(Object.keys(this.props.time)[this.state.tabIndex])
    }
  }

  drawGraph(data){
    var xDistance = canvas.clientWidth / (data.y.length - 1) ;

    var range = this.plotRange(data.y)
    var tableHeight = (canvas.clientHeight - 125);
    var yDistance = tableHeight / (range.max - range.min);
    var segments = [];
    for (var i = 0; i < data.y.length; i++) {
      var adjustedPoint;
      if (this.state.tabIndex === 1){
        if (i === 0) {
          adjustedPoint = tableHeight - (yDistance  * (data.y[data.y.length - 1] - range.min) - 75);
        }
        else {
          adjustedPoint = tableHeight - (yDistance  * (data.y[i - 1] - range.min) - 75);
        }
      }
      else{
        adjustedPoint = tableHeight - (yDistance  * (data.y[i] - range.min) - 75);
      }
      segments.push(new paper.Point((xDistance * i), adjustedPoint));
    }

    path = new paper.Path(segments)
    path.strokeColor = "#114FCB"

    path.simplify();

    path.strokeCap = 'round';
    path.strokeWidth = 2;

    pathGradient = path.clone();
    pathGradient.strokeWidth = 0;

    pathGradient.add(new paper.Point(canvas.clientWidth + 4,  segments[data.y.length - 1].y - 4));
    pathGradient.add(new paper.Point(canvas.clientWidth + 4, canvas.clientHeight));
    pathGradient.add(new paper.Point(- 4, canvas.clientHeight));
    pathGradient.add(new paper.Point(- 4, segments[0].y - 4));

    pathGradient.fillColor = {
        gradient: {
            stops: [ "rgba(17,79,203, 0.3)" , "rgba(17,79,203, 0)"]
        },
        origin: [0, 100],
        destination: [0, canvas.clientHeight]
    };
  }

  plotRange(yValues){
    var yMin=Math.min(...yValues);
    var yMax=Math.max(...yValues);

    var yDiff = yMax - yMin;
    if (yDiff < .5){
      yMin -= .025;
      yMax += .025;
    }
    else{
      yMin -= .1 * (yDiff);
      yMax += .1 * (yDiff);
    }

    return {
      max: parseFloat((yMax).toFixed(3)),
      min: parseFloat((yMin).toFixed(3))
    }
  }


  updateDividers(type){
    document.getElementById("time_cover").classList.add("time-reveal");
    setTimeout(()=>{
      document.getElementById("time_cover").classList.remove("time-reveal");
    }, 1000);
    var times;
    if (type === "tod") {
      times = ["early morning", "morning", "midday", "afternoon", "evening", "late evening"];
    }
    else if (type === "dow") {
      times = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    }
    else{
      times = ["winter", "spring", "summer", "fall"];
    }
    var dividers = times.map((time, index)=>{
      return(
        <div className = "table-divider sp-c-2" key = {"time_divider_" + index} style = {{width: "calc(100% / " + times.length + ")", borderRight: (index !== times.length - 1)? '2px dashed #1D2534': 'none'}}>
           {time}
        </div>
      )
    });

    this.setState({
      sectionDividers: dividers
    });

    if (path !== undefined) {
      path.remove();
      pathGradient.remove();
      var bounds = document.getElementById("time_table").getBoundingClientRect();
      canvas.width = bounds.width;
      canvas.height = bounds.height
    }
    this.drawGraph(this.props.time[type])
  }
}
export default TimeSection;
