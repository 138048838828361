import React, {Component} from 'react';
import '../css/segment_sections/CharacteristicsSection.css';

class CharacteristicsSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      moeChartWidth: 0
    }
  }
  render (){
    var urbanSVG;
    if(this.props.characteristics["urbanicity"].cities === true) {
      urbanSVG = <svg className = "svg-container" viewBox="0 0 783 346">
          <g fill="none" fillRule="evenodd">
            <path className = "svg-path"
              d="m40 187h50.8931298v-32l29.3282442-34 27.603053 32v20h31.053436v-68h50.893129v34h19.624046v-94h32.994275v142h29.328244v-115h6.900764v-10h7.763358v-9h6.038168v-13 13h5.175573v9h7.763359v10h7.763358v99h26.093512v-44h73.967557v28h29.328244v-16h25.877863v14h26.222901v-48h20.357252v-21h39.679389v103h30.927238v15h57.423907v16h60v88"
              fillRule="nonzero"
              stroke="#CF444F"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              fill="none"/>
          </g>
        </svg>
    }
    else {
      urbanSVG = <svg className = "svg-container" viewBox="0 0 783 346">
        <g fill="none" fillRule="evenodd">
            <path className = "svg-path"
              d="M743,142 L678.037016,142 L678.037016,109 L705.02164,109 L689.030752,85 L698.025626,85 L681.035308,61 L690.030182,61 L673.039863,37 L657.048975,61 L666.04385,61 L650.052961,85 L659.047836,85 L643.056948,109 L669.042141,109 L669.042141,142 L608.076879,142 L608.076879,118 L627.066059,118 L615.072893,100 L622.068907,100 L609.07631,82 L615.072893,82 L603.079727,65 L591.08656,82 L597.083144,82 L585.089977,100 L592.085991,100 L580.092825,118 L599.082005,118 L599.082005,142 L531.120729,142 L531.120729,108 L519.127563,84 L495.14123,68 L470.155467,84 L458.162301,108 L458.162301,142 L510.132688,142 L510.132688,108 L477.901054,108 L477.901054,151 L436.302424,151 L436.302424,76 C436.217361,65.3333333 430.844533,60 420.183941,60 C409.523349,60 404.193052,65.3333333 404.193052,76 L404.193052,151 L379.806948,151 L304.849658,116 L281.263098,116 L356.220387,151 L332.234055,151 L257.276765,116 L233.290433,116 L308.247722,151 L284.354137,151 L209.396847,116 L185.317768,116 L260.275057,151 L236.288724,151 L161.331435,116 L137.345103,116 L212.302392,151 L41,151 L41,309"
              stroke="#CF444F"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              fillRule="nonzero"
              fill="none"
              transform="translate(392.000000, 173.000000) scale(-1, 1) translate(-392.000000, -173.000000)"
              />
        </g>
      </svg>
    }

    return (
      <div id = "characteristics_section" className = "section-view characteristics-section">
        <div className = "insights-divider sp-p-1">
          <img src = './img/icons/info.svg' className = "icon" style = {{margin: "0 100% 8px"}}/>
          <div className = "insights-copy">
            The following insights describe statistically significant correlations between this geosocial segment and 3rd party datasets across the entire United States.
          </div>
        </div>
        <div className = "section-content wide">
          <div className = "sp-h-2 header ani-1" style = {{marginTop: "104px"}}>
            characteristics of high scoring neighborhoods
          </div>
          <div className = "description sp-p-1 ani-1" style = {{paddingBottom: "52px", maxWidth: "850px", margin: "0 auto"}}>
            Every geosocial segment is analyzed across hundreds of third party and public location datasets to understand the characteristics of neighborhoods where this activity is prevalent. Segments are not exclusively linked to or in any way based on these datasets or attributes. The following insights reference statistically significant correlations between the datasets. Sources include the American Communities Survey, Zillow Home Value Data, and Point of Interest data.
          </div>
          <table className = "characteristics-table">
            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/age.svg"/>
                </td>
                <th>age</th>
              </tr>
              <tr className = "characteristics-container reverse">
                <td className = "characteristics-copy characteristics-content sp-p-1 ani-1" style = {{animationDelay: "1500ms"}}>
                  {this.props.characteristics["age"].copy}
                </td>
                {this.createMOEchart("age", "")}
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/income.svg"/>
                </td>
                <th>income</th>
              </tr>
              <tr className = "characteristics-container">
                <td className = "characteristics-copy characteristics-content sp-p-1 ani-1" style = {{animationDelay: "1500ms"}}>
                  <div className = "ani-1">
                    {this.props.characteristics["income"].copy}
                  </div>
                </td>
                {this.createMOEchart("income", "right")}
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/ethnicity.svg"/>
                </td>
                <th>ethnicity</th>
              </tr>
              <tr className = "characteristics-container reverse">
                <td className = "characteristics-copy characteristics-content sp-p-1">
                  <div className = "ani-1" style = {{animationDelay: "750ms"}}>
                    {this.props.characteristics["ethnicity"].copy}
                  </div>
                </td>
                <td className = "characteristics-content">
                  <div className = "type-visual sp-h-3 ani-1" style = {{animationDelay: "375ms"}}>
                    {((this.props.characteristics["ethnicity"].diversity === true)? "high diversity" : "low diversity")}
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/education.svg"/>
                </td>
                <th>education</th>
              </tr>
              <tr className = "characteristics-container fixed">
              <td className = "characteristics-content">
                {this.createBarChart("education", "uni")}
              </td>
                <td className = "characteristics-content">
                  {this.createBarChart("education", "hs")}
                </td>
                <td className = "characteristics-copy ani-1 characteristics-content sp-p-1" style = {{animationDelay: "750ms"}}>
                  {this.props.characteristics["education"].copy}
                </td>
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/workforce.svg"/>
                </td>
                <th>workforce</th>
              </tr>
              <tr className = "characteristics-container fixed">
                <td className = "type-profession characteristics-content">
                  <div className = "ani-1 sp-c-2" style = {{marginBottom: "14px"}}>
                    profession
                  </div>
                  <div className = "ani-1 sp-p-2" style = {{animationDelay: "375ms", marginBottom: "8px", textTransform: "capitalize"}}>
                    {this.props.characteristics["employment"].top_professions[0]}
                  </div>
                  <div className = "ani-1 sp-p-2" style = {{animationDelay: "750ms", marginBottom: "61px", textTransform: "capitalize"}}>
                    {this.props.characteristics["employment"].top_professions[1]}
                  </div>
                </td>
                <td className = "type-profession characteristics-content">
                {this.createBarChart("employment", "unemployment")}

                </td>
                <td className = "characteristics-copy ani-1 characteristics-content sp-p-1" style = {{animationDelay: "1125ms"}}>
                  {this.props.characteristics["employment"].copy}
                </td>
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/urbanicity.svg"/>
                </td>
                <th>urbanicity</th>
              </tr>
              <tr className = "characteristics-container reverse">
                <td className = "characteristics-copy characteristics-content sp-p-1">
                  <div className = "ani-1" style = {{animationDelay: "750ms"}}>
                    {this.props.characteristics["urbanicity"].copy}
                  </div>
                </td>
                <td className = "characteristics-content">
                  {urbanSVG}
                </td>
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/home.svg"/>
                </td>
                <th>home ownership</th>
              </tr>
              <tr className = "characteristics-container">
                <td className = "characteristics-copy ani-1 characteristics-content sp-p-1" style = {{animationDelay: "1500ms"}}>
                  {this.props.characteristics["home_ownership"].copy}
                </td>
                {this.createStackedChart("home_ownership")}
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/political.svg"/>
                </td>
                <th>2016 election results</th>
              </tr>
              <tr className = "characteristics-container reverse">
                <td className = "characteristics-copy ani-1 characteristics-content sp-p-1" style = {{animationDelay: "1500ms"}}>
                  {this.props.characteristics["political_affiliation"].copy}
                </td>
                {this.createStackedChart("political_affiliation")}
              </tr>
            </tbody>

            <tbody className = "characteristics-row">
              <tr className = "characteristics-title sp-h-4 ani-1">
                <td>
                  <img className = "icon" src ="./img/icons/insights.svg"/>
                </td>
                <th>additional insights</th>
              </tr>
              <tr className = "characteristics-container ani-1">
                {
                    this.props.characteristics["other_insights"].map((insight, index)=>{
                    return(
                      <td key = {"characteristics_insight_" + index} className = "characteristics-insight characteristics-content" style = {{marginBottom: "40px"}}>
                        <div className = "sp-p-1">
                          {insight}
                        </div>
                      </td>
                    )
                  })
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  componentDidMount(){
    this.setState({
      moeChartWidth: document.getElementsByClassName("characteristics-content")[0].getBoundingClientRect().width
    })

    window.addEventListener("resize", this.moeResize.bind(this), false);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.moeResize.bind(this), false);
  }

  moeResize(){
    this.setState({
      moeChartWidth: document.getElementsByClassName("characteristics-content")[0].getBoundingClientRect().width
    });
  }

  createBarChart(data, value){
    var title;
    if (value === "hs") {
      title = "high school degrees"
    }
    else if (value === "uni") {
      title = "bachelor degrees"
    }
    else{
      title = value;
    }
    var max =(value === "unemployment")? 10 : 100;
    var adjustedValue = this.props.characteristics[data][value];
    var adjustedAverage = this.props.characteristics[data][value + "_avg"];

    var barColor = (value === "unemployment")? "#EAC8A6" : "#114FCB"


    if (value === "unemployment") {
      adjustedValue = adjustedValue * 10;
      adjustedAverage = adjustedAverage * 10;
    }
    return(
      <div className = "chart-content bar">
        <div className = "sp-c-2 ani-1" style = {{textTransform: "uppercase", width: "100%", marginBottom: "18px", animationDelay: "375ms"}}>
          {title}
        </div>
        <div className = "sp-h-3">
          {parseFloat(this.props.characteristics[data][value]).toFixed(1) + "%" }
        </div>
        <div className = "bar-chart-container ani-4" style ={{marginBottom: "8px", animationDelay: "750ms"}}>
          <div className = "primary-bar" style = {{width: adjustedValue+ "%",   left: "0", backgroundColor: barColor}}/>
          <div className = "background-bar"/>
          <div className = "average-container" style = {{left: "calc( " + adjustedAverage + "% - 100px"}}>
            <div className = "average-title ani-1 sp-c-2" style = {{animationDelay: "750ms"}}>
              {"national avg (" + this.props.characteristics[data][value + "_avg"] + "%)"}
            </div>
            <div className = "moe-value avg ani-2" style = {{animationDelay: "1500ms"}}/>
          </div>
        </div>
        <div className = "chart-content legend" style = {{width: "100%"}}>
          <div className = "chart-value avg sp-c-2">
            0%
          </div>
          <div className = "chart-value avg sp-c-2">
            {max + "%"}
          </div>
        </div>
      </div>
    )
  }

  createMOEchart(data, right){
    var percentDiff = (((this.props.characteristics[data].median - this.props.characteristics[data].us_median) / this.props.characteristics[data].us_median) * 100).toFixed(2);
    var legends = (data === "age")? ["younger", "older"] : ["low", "high"];
    var barColor = (data === "age")? "#1CB6C5" : "#2EAC75";
    var minValue = (data === "age")? 25 : 40000;
    var maxValue = (data === "age")? 45 : 75000;

    var range = (maxValue - minValue);



    var centralValue = (range/2) + minValue;
    var adjustedValue = centralValue * (1 + (parseFloat(percentDiff)/100));
    var adjustedPoint = this.state.moeChartWidth /(range / 2) * (adjustedValue - minValue) / 2;


    var moeAdjusted = (parseFloat(this.props.characteristics[data].margin_of_error)/ ((data === "age")? 100 : 100000)) * range //centralValue * (parseFloat(this.props.characteristics[data].margin_of_error)/100);
    var moeLow = this.state.moeChartWidth /(range / 2) * ((adjustedValue-moeAdjusted) - minValue) / 2
    var moeValue = (Math.ceil(adjustedPoint - moeLow) + 2 ) * 2;
    if (moeValue % 2 !== 0) {
      moeValue += 1;
    }
  //  var medianPoint = this.state.moeChartWidth /(range / 2) * (medianValue - minValue) / 2;


    return(
      <td className = "characteristics-content chart-content sp-h-2">
        <div className = {"type-visual sp-h-3  ani-1" + right} style = {{width: "100%"}}>
          {((percentDiff > 0)? "+": "") + percentDiff + "%"}
        </div>
        <div id={data + "_chart"} className = "moe-chart">
          <div className = "moe-axis"/>
          <div className = "moe-value median"/>
          <div className = "moe-value bar ani-3" style = {{left: Math.floor(adjustedPoint - (moeValue/2)) + 1 + "px", width: moeValue + "px", animationDelay: "1125ms", backgroundColor: barColor}}/>
          <div className = "moe-value ani-2" style = {{left: Math.floor(adjustedPoint) + "px", animationDelay: "750ms"}}/>
        </div>
        <div className = "legend-row">
          <div className = "sp-c-2">
            {legends[0]}
          </div>
          <div className = "sp-c-2 middle-legend">
            national median
          </div>
          <div className = "sp-c-2">
            {legends[1]}
          </div>
        </div>
      </td>
    )
  }

  createStackedChart(data){
    var leftValue = (data === "political_affiliation")? "democrat" : "own";
    var rightValue = (data === "political_affiliation")? "republican" : "rent";
    var leftColor = (data === "political_affiliation")? "#114FCB" : "#FD884E";
    var rightColor = (data === "political_affiliation")? "#CF444F" : "#F3CA60";

    return(
      <td className = "characteristics-content chart-content sp-h-2">
        <div className = "chart-value sp-h-3 ani-1" style = {{animationDelay: "750ms", display: ((data === "political_affiliation")? "none" : "initial")}}>
          {parseFloat(this.props.characteristics[data][leftValue]).toFixed(1) + "%"}
        </div>
        <div className = "chart-value right sp-h-3 ani-1" style = {{animationDelay: "750ms", display: ((data === "political_affiliation")? "none" : "initial")}}>
          {parseFloat(this.props.characteristics[data][rightValue]).toFixed(1) + "%"}
        </div>
        <div className = "average-container stacked" style = {{left: "calc( " + this.props.characteristics[data]["avg_" + leftValue] + "% - 100px)"}}>
          <div className = "average-title ani-1 sp-c-2" style = {{animationDelay: "750ms"}}>
          {"national avg (" + parseFloat(this.props.characteristics[data]["avg_" + leftValue]).toFixed(1) + "%, " + parseFloat(this.props.characteristics[data]["avg_" + rightValue]).toFixed(1) + "%)"}
          </div>
          <div className = "moe-value avg ani-2" style = {{animationDelay: "1500ms"}}/>
        </div>
        <div id={data +'_chart_l'} className = "primary-bar left trans-1" style = {{width: this.props.characteristics[data][leftValue] + "%", backgroundColor: leftColor, transitionDelay: "1125ms"}}>
        </div>
        <div id={data +'_chart_r'} className = "primary-bar right trans-1" style = {{width: this.props.characteristics[data][rightValue] + "%", backgroundColor: rightColor, transitionDelay: "1125ms"}}>
        </div>
        <div className = "chart-value avg sp-c-2">
          {leftValue}
        </div>
        <div className = "chart-value avg sp-c-2">
          {rightValue}
        </div>
      </td>
    )
  }
}
export default CharacteristicsSection;
