import React from 'react';
import {Route, BrowserRouter as Router} from 'react-router-dom';
import SegmentView from './views/SegmentView';
import HomeView from './views/HomeView';
import UnsupportedView from './views/UnsupportedView';
import './css/App.css';

const App = () => (
  <Router>
    <div>
      <Route exact path='/' component={HomeView} />
      <Route path='/segments' component={SegmentView} />
      <Route path= '/unsupported' component={UnsupportedView} />
    </div>
  </Router>
)


export default App;
