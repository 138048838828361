var PubSub = require('pubsub-js');

const constants = {
  SEGMENT_SELECTED: "SEGMENT_SELECTED",
  TO_SEGMENT_VIEW: "TO_SEGMENT_VIEW",
  SHOW_GRID_VIEW: "SHOW_GRID_VIEW",
  UPDATE_SECTION_CONTROL: "UPDATE_SECTION_CONTROL",
  SHARE_SELECTED: "SHARE_SELECTED",
  CONTACT_DISPLAYED: "CONTACT_DISPLAYED"
}

var pub_function = function(event){
  return function(data){
    return PubSub.publish(event, data);
  }
}

var sub_function = function(event){
  return function(callback){
    return PubSub.subscribe(event, callback);
  }
}

var clear_all_function = ()=>{
  return PubSub.clearAllSubscriptions();
}
var publish = {
  segment_selected: pub_function(constants.SEGMENT_SELECTED),
  to_segment_view: pub_function(constants.TO_SEGMENT_VIEW),
  show_grid_view: pub_function(constants.SHOW_GRID_VIEW),
  update_section_control: pub_function(constants.UPDATE_SECTION_CONTROL),
  share_selected: pub_function(constants.SHARE_SELECTED),
  contact_displayed: pub_function(constants.CONTACT_DISPLAYED),


}

var subscribe = {
  segment_selected: sub_function(constants.SEGMENT_SELECTED),
  to_segment_view: sub_function(constants.TO_SEGMENT_VIEW),
  show_grid_view: sub_function(constants.SHOW_GRID_VIEW),
  update_section_control: sub_function(constants.UPDATE_SECTION_CONTROL),
  share_selected: sub_function(constants.SHARE_SELECTED),
  contact_displayed: sub_function(constants.CONTACT_DISPLAYED),

}

module.exports = {
  publish: publish,
  subscribe: subscribe,
  clear_all: clear_all_function
}
