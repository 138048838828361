import React, {Component} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css'
import '../css/segment_sections/GeographySection.css';

import { Map, loadModules } from '@esri/react-arcgis';


var startLocation = [-97, 37];
var nationExtent ={
  xmin: -125,
  ymin: 21,
  xmax: -66,
  ymax: 48,
  spatialReference: {
    wkid: 4326
  }
}
var cameraOptions ={
  duration: 1300,
  easing: "ease-in-out"
}

const baseTiles = ((process.env.REACT_APP_LOCATION !== "production")? "https://s3.amazonaws.com/spatial-cdn/taxonomy-api/tiles/":"https://s3.amazonaws.com/spatial-cdn/taxonomy-api-dev/tiles/");

class GeographySection extends Component {
  constructor(props){;
    super(props);

    this.state = {
      selectedCity: null,
      map: undefined,
      mapView: undefined,
      minZoom: 2,
    }

    this.handleMapLoad = this.handleMapLoad.bind(this);
  }

  render (){
    return (
      <div id = "geography_section" className = "section-view geography-section">
        <div className = "section-content">

          <div className = "header sp-h-2 ani-1">
            geography
          </div>
          <div className = "description sp-p-1 ani-1" style = {{marginBottom: "40px"}}>
            Segments are trained on data from a diverse sample of thousands of locations, representing the behaviors that can be consistently measured in any community. Levels of activity vary across block groups within cities and may be more prevalent in certain regions. Segments are measured and delivered at granular levels of location, often at the Block Group level. This heatmap displays the prevalence of this segment across the U.S. and at a more granular level for three U.S. markets.
          </div>
          <div className = "geography-content">
            <div className = "cities-list">
              {this.createCitiesList()}
            </div>
            <div className = "map-container">
              <div className = "map-view-container" style = {{pointerEvents: ((this.state.selectedCity === null)? "none": "initial")}}>
                <Map id = "map" className = "map-view" onLoad={this.handleMapLoad}/>
              </div>
              <div className = "map-legend">
                <div className = "legend-value sp-c-2">
                low
                </div>
                <div className = "legend-value sp-c-2">
                high
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.id !== this.props.id) {
      this.setState({
        selectedCity: null,
      }, ()=>{
        this.findBounds();
        this.updateMapLayers();
      });
    }
  }

  handleMapLoad(map, mapView){
    this.setState({
      map: map,
      mapView: mapView
    }, ()=>{
      loadModules(['esri/layers/VectorTileLayer', 'esri/Basemap', 'esri/geometry/Extent']).then(([VectorTileLayer, Basemap, Extent])=>{
        //spatial base layer
        var baseLayer = new Basemap({
          baseLayers:[
            new VectorTileLayer({
              portalItem: {
                id: "251f30a1a3d140f982b08b0d4c44202f"
              }
            })
          ]
        })

        this.state.mapView.on('mouse-wheel',(event)=>{
        //  console.log("min: "  + minZoom);
        //  console.log("max: " + maxZoom);
          if (this.state.selectedCity === null) {
            event.stopPropagation();
          }
          else {
            if (event.deltaY <= 0 && (this.state.mapView.zoom >= 11)) {
              event.stopPropagation();
            }
            else if(event.deltaY >= 0 && (this.state.mapView.zoom <= this.state.minZoom)) {
              event.stopPropagation();
            }
          }
        })

        this.state.mapView.on("double-click",(event)=>{
          if (this.state.mapView.zoom >= ((this.state.selectedCity === null)? 6 : 11)) {
            event.stopPropagation();
          }
        })

        this.state.mapView.on("key-down",(event)=>{
          var zoomInKeys = ["+","="];
          var zoomOutKeys = ["-", "-"]

          var keyPressed = event.key;
          if (zoomInKeys.indexOf(keyPressed) !== -1 && (this.state.mapView.zoom >= ((this.state.selectedCity === null)? 6 : 11))) {
           event.stopPropagation();
          }
          else if (zoomOutKeys.indexOf(keyPressed) !== -1 && (this.state.mapView.zoom <= this.state.minZoom)) {
            event.stopPropagation();
          }
        })

        this.state.map.basemap = baseLayer;
        this.state.mapView.ui.components = ["attribution"];
        this.findBounds();
      })
      this.updateMapLayers();
    })
  }

  createCitiesList(){
    var list = this.props.geography.features.map((city, index)=>{
      return(
        <div key = {"city_" + index} className = "city-container ani-1">
          <button id = {"city_dropdown: " + index} className = "city-button sp-b-2" onClick = {()=>{
            this.setState({
              selectedCity: ((this.state.selectedCity === index)? null : index)
            }, ()=>{
              this.findBounds();
            });
          }}>
            <div>
              {city.properties.name + ", " +  city.properties.state_id}
            </div>
            <img alt = "City Dropdown List" className = {"city-chevron " + ((this.state.selectedCity === index)? "expanded" : "")} src = "/img/icons/arrow.svg"/>
          </button>
          <div className = {"city-content " + ((this.state.selectedCity === index)? "expanded" : "")}>
            <div className = "sp-b-2" style = {{fontWeight: "300"}}>
              {"population: " + city.properties.population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </div>
      )
    });

    return(list);
  }

  updateMapLayers(){
    var currentId = this.props.id;
    loadModules(['esri/layers/WebTileLayer']).then(([WebTileLayer])=>{
      this.state.map.removeAll();
      var mapLayers = [];

      mapLayers.push(new WebTileLayer({
        id: "national_layer",
        title: "Spatial segments, county scores",
        copyright: "spatial.ai",
        urlTemplate: baseTiles + currentId +'/nationwide/{level}/{col}/{row}.png',
        })
      );

      for (var i = 0; i < this.props.geography.features.length; i++) {
        var folderName = this.props.geography.features[i].properties.name.toLowerCase().replace(new RegExp(" ", 'g'), "_") + "_" + this.props.geography.features[i].properties.state_id;
        mapLayers.push(new WebTileLayer({
          id: "city_layer_" + i,
          title: "Spatial segments, county scores",
          copyright: "spatial.ai",
          urlTemplate: 'https://cdn.spatial.ai/taxonomy-api-dev/tiles/' + currentId + "/" + folderName + "/{level}/{col}/{row}.png"
          })
        );
      }

      this.state.map.addMany(mapLayers)
    });
  }

  getCityBounds(){
    var cityProperties = this.props.geography.features[this.state.selectedCity].properties;
    return([[cityProperties.minLng, cityProperties.minLat],[cityProperties.maxLng, cityProperties.maxLat]]);
  }

  //Find the coordinates of the furthest points
  findBounds(){
    loadModules(['esri/geometry/Extent', 'esri/geometry/Point']).then(([Extent, Point])=>{
      var nationalLayer = this.state.map.findLayerById("national_layer");
      if (this.state.selectedCity === null) {
        if (nationalLayer !== undefined) {
            nationalLayer.visible = true;
        }
        this.state.mapView.goTo({
          target: new Extent(nationExtent),
        }, cameraOptions).then(()=>{
          if (this.state.mapView.zoom < 3) {
            this.state.mapView.zoom = 2;
          }
        });
      }

      else{
        //  map.scrollZoom.enable();
        var tempBounds = this.getCityBounds();
        var tempExtent = new Extent({
          xmin: tempBounds[0][0],
          ymin: tempBounds[0][1],
          xmax: tempBounds[1][0],
          ymax: tempBounds[1][1]
        })
        this.state.mapView.goTo(tempExtent, cameraOptions).then(()=>{
          var currentZoom = this.state.mapView.zoom;
          if (nationalLayer !== undefined) {
              nationalLayer.visible = ((this.state.selectedCity === null)? true: false);
          }
          console.log(this.state.mapView.zoom);
          this.setState({
            minZoom: currentZoom - 1
          })
        });

        //add padding to the mapview
      //  this.state.mapView.zoom = this.state.mapView.zoom - 1;
      }
    });
  }
}

export default GeographySection;
