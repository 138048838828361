import React, { Component } from 'react';
import '../css/segment_sections/CommerceSection.css';

class CommerceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    }
  }

  render() {
    return (
      <div id="commerce_section" className="section-view commerce-section">
        <div className="section-content">
          <div className="header sp-h-2 ani-1">
            retail & restaurant correlation
          </div>
          <div className=" description sp-p-1 ani-1" style={{ marginBottom: "80px" }}>
            People's interests play a role in driving the success of businesses located in a community. Understanding the relationships between segments and business types is key to knowing where restaurants and retailers will succeed.
          </div>
          <div className="table-container">
            <div className="table-header sp-h-4">
              Drivers
            </div>
            <div id="drivers_desc" className=" description sp-p-1 ani-1">
              The business types below often drive the behavior of this segment.
            </div>
            {this.updateCommerceTable("drivers")}
          </div>
          <div className="table-container">
            <div className="table-header sp-h-4">
              Opportunities
            </div>
            <div id="opportunities_desc" className=" description sp-p-1 ani-1">
              The business types below benefit from being in proximity to this segment.
            </div>
            {this.updateCommerceTable("opportunities")}
          </div>
          <div className="table-container">
            <div className="table">
              <div className="table-header sp-h-4">
                <img className="icon" src="./img/icons/price.svg" />
                price point
              </div>
              <div className="price-container">
                <div className="dollar-container">
                  <img alt="1 Dollar Sign" className="dollar-img" src="img/misc/dollar_sign.svg" />
                  <div id="dollar_1" className="dollar-background" style={{ backgroundColor: ((this.props.commerce.price.dollars >= 1) ? "#2EAC75" : "transparent") }} />
                </div>
                <div className="dollar-container">
                  <img alt="2 Dollar Signs" className="dollar-img" src="img/misc/dollar_sign.svg" />
                  <div id="dollar_2" className="dollar-background" style={{ backgroundColor: ((this.props.commerce.price.dollars >= 2) ? "#2EAC75" : "transparent") }} />
                </div>
                <div className="dollar-container">
                  <img alt="3 Dollar Signs" className="dollar-img" src="img/misc/dollar_sign.svg" />
                  <div id="dollar_3" className="dollar-background" style={{ backgroundColor: ((this.props.commerce.price.dollars >= 3) ? "#2EAC75" : "transparent") }} />
                </div>
                <div className="dollar-container">
                  <img alt="4 Dollar Signs" className="dollar-img" src="img/misc/dollar_sign.svg" />
                  <div id="dollar_4" className="dollar-background" style={{ backgroundColor: ((this.props.commerce.price.dollars >= 4) ? "#2EAC75" : "transparent") }} />
                </div>
              </div>
            </div>
          </div>

          <div className=" description sp-p-1 ani-1">
            Neighborhoods that score highly for this behavior have above average rates of restaurants that are rated as this price point.
          </div>
        </div>
      </div>
    );
  }

  updateCommerceTable(poi_type) {

    // if there is no data, return this
    if (Object.keys(this.props.commerce[poi_type]).length === 0) {
      return (<div id='commerce-drivers-no-data' className="description sp-p-1"
        style={{ color: "#6C7583", fontWeight: 300, border: "2px solid #3C4047", marginBottom: "40px",marginTop: "20px", padding: "10px", fontSize:"12px", fontWeight: "500"}}>
        This segment is not clearly driven by any particular POI type.
      </div>)
    }

    // else, you good to go bud. Return those poi types and examples like a badass! 
    var dict = this.props.commerce[poi_type];
    let columns = []
    for (var key in dict) {
      let row = []

      row.push(
        <div className='commerce-header'>
          <span>{key}</span>
          <img className="commerce-caret" src="./img/icons/arrow.svg" />
        </div>
      )

      dict[key].forEach(item => row.push(
        <div className="commerce-example">{item}</div>)
      );
      columns.push(<div className="commerce-col-wrapper" onClick={this.toggleExamples}>{row}</div>)
    };

    return (
      <div className="commerce-table-wrapper">
        {columns}
      </div>
    )
  }

  toggleExamples(e) {

    var caret = e.currentTarget.querySelector('.commerce-caret')
    caret.classList.toggle('open');

    var examples = e.currentTarget.querySelectorAll('.commerce-example')

    examples.forEach(item => item.classList.toggle('open'))


    // console.log(examples)

  }

}

export default CommerceSection;
